import React, { useState } from 'react';
import { motion } from 'framer-motion';

export default function DemoHero() {
    const [showDeadstock, setShowDeadstock] = useState(false);

    const content = {
        deadstock: {
            title: "Maximize Returns on",
            titleHighlight: "Excess Inventory",
            description: "Transform your deadstock challenges into opportunities. Book a demo to discover how our platform connects you with retailers where your products are in demand.",
            benefits: [
                "How to find perfect markets for your excess inventory",
                "Access to our network of 500+ active retailers",
                "Smart inventory reallocation strategies",
                "Free POS system with advanced analytics"
            ]
        },
        social: {
            title: "Boost Sales with",
            titleHighlight: "Chat Commerce",
            description: "Transform your WhatsApp into a powerful sales channel. Schedule a demo to see how our platform can help you engage customers and drive conversions.",
            benefits: [
                "Create an engaging WhatsApp storefront",
                "Automate customer interactions and sales",
                "Process secure in-chat payments",
                "Track performance with detailed analytics"
            ]
        }
    };

    const activeContent = showDeadstock ? content.deadstock : content.social;

    return (
        <section className="relative min-h-screen flex items-center overflow-hidden">
            {/* Toggle Switch */}
            <div className="fixed top-24 right-4 flex items-center gap-3 bg-white/80 backdrop-blur-sm p-2 rounded-full shadow-md z-50">
                <span className={`text-sm transition-colors duration-300 ${!showDeadstock ? 'text-primary font-medium' : 'text-gray-500'}`}>
                    Social Commerce
                </span>
                <button
                    onClick={() => setShowDeadstock(!showDeadstock)}
                    className="relative w-14 h-7 rounded-full transition-colors duration-300"
                    style={{
                        backgroundColor: showDeadstock ? '#7E22CE' : '#F3F4F6'
                    }}
                >
                    <div
                        className="absolute top-1 left-1 w-5 h-5 bg-white rounded-full shadow-md transition-transform duration-300 ease-in-out"
                        style={{
                            transform: showDeadstock ? 'translateX(28px)' : 'translateX(0)'
                        }}
                    />
                </button>
                <span className={`text-sm transition-colors duration-300 ${showDeadstock ? 'text-primary font-medium' : 'text-gray-500'}`}>
                    Deadstock Solution
                </span>
            </div>

            {/* Background Effects */}
            <div className="absolute inset-0">
                <svg className="absolute inset-0 w-full h-full opacity-10" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <pattern id="grid" x="0" y="0" width="40" height="40" patternUnits="userSpaceOnUse">
                            <path d="M0 40V0M40 40V0M0 40H40" fill="none" stroke="currentColor" strokeWidth="1" strokeOpacity="0.2"/>
                        </pattern>
                    </defs>
                    <rect width="100%" height="100%" fill="url(#grid)" />
                </svg>
                <motion.div 
                    className="absolute top-0 -left-40 w-80 h-80 bg-white rounded-full mix-blend-multiply filter blur-3xl opacity-10"
                    animate={{ 
                        x: [0, 100, 0], 
                        y: [0, 50, 0] 
                    }}
                    transition={{ 
                        duration: 20, 
                        repeat: Infinity,
                        ease: "linear" 
                    }}
                />
                <motion.div 
                    className="absolute bottom-0 -right-40 w-80 h-80 bg-white rounded-full mix-blend-multiply filter blur-3xl opacity-10"
                    animate={{ 
                        x: [0, -100, 0], 
                        y: [0, -50, 0] 
                    }}
                    transition={{ 
                        duration: 15, 
                        repeat: Infinity,
                        ease: "linear" 
                    }}
                />
            </div>

            <div className="container mx-auto px-4 md:px-8">
                <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center">
                    {/* Left Content */}
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                        className="space-y-8"
                    >
                        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight text-gray-900">
                            {activeContent.title}
                            <span className="block text-primary mt-2">{activeContent.titleHighlight}</span>
                        </h1>
                        <p className="text-gray-600 text-lg md:text-xl leading-relaxed">
                            {activeContent.description}
                        </p>

                        {/* Benefits */}
                        <div className="space-y-4 pt-4">
                            <p className="text-lg font-medium text-gray-900">
                                During your demo, {showDeadstock ? "we'll show you:" : "you'll learn:"}
                            </p>
                            <ul className="space-y-4">
                                {activeContent.benefits.map((benefit, index) => (
                                    <motion.li 
                                        key={index}
                                        initial={{ opacity: 0, x: -20 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        transition={{ delay: 0.2 + (index * 0.1) }}
                                        className="flex items-center gap-3"
                                    >
                                        <div className="w-6 h-6 rounded-full bg-primary/10 flex items-center justify-center">
                                            <svg className="w-4 h-4 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                            </svg>
                                        </div>
                                        <span className="text-gray-600">{benefit}</span>
                                    </motion.li>
                                ))}
                            </ul>
                        </div>
                    </motion.div>

                    {/* Right Form */}
                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                        className="relative"
                    >
                        <div className="bg-white rounded-2xl shadow-xl p-6 md:p-8">
                            <div className="text-center mb-8">
                                <h2 className="text-2xl font-bold text-gray-900 mb-2">
                                    Book Your Free Demo
                                </h2>
                                <p className="text-gray-600">
                                    We’ll respond within 24 hours to schedule your session
                                </p>
                            </div>

                            {/* Information about the data they'll provide */}
                            <div className="space-y-6">
                                <div className="flex items-center gap-3">
                                    <svg className="w-6 h-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M16 2a2 2 0 012 2v12a2 2 0 01-2 2H8a2 2 0 01-2-2V4a2 2 0 012-2h8z"
                                        />
                                    </svg>
                                    <p className="text-lg font-medium text-gray-700">Full Name</p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <svg className="w-6 h-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M4 6a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2V6z"
                                        />
                                    </svg>
                                    <p className="text-lg font-medium text-gray-700">Phone Number</p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <svg className="w-6 h-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M21 12a9 9 0 10-18 0 9 9 0 0018 0z"
                                        />
                                    </svg>
                                    <p className="text-lg font-medium text-gray-700">Email Address</p>
                                </div>
                            </div>

                            <a
                                href="https://calendar.app.google/BXgPp5Ks5SyhBHLbA"
                                target="_blank" rel="noreferrer"
                                className="w-full bg-primary hover:bg-primary-hover text-white font-medium py-3 px-6 rounded-lg transition duration-200 flex items-center justify-center gap-2 mt-8"
                            >
                                Schedule Demo
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </a>

                            <p className="text-center text-sm text-gray-500 mt-6">
                                By submitting this form, you agree to our terms and privacy policy.
                            </p>
                        </div>
                    </motion.div>

                </div>
            </div>
        </section>
    );
}
import React from 'react';
import Layout from '../components/layout/layout';
import DemoHero from '../components/demo/sections/hero';

export default function Demo({ initialData }) {
  return (
    <Layout initialData={initialData}>
      <DemoHero />
    </Layout>
  );
}